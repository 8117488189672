html,
body {
  height: 100%;
  word-wrap: break-word;
}

#root,
#container {
  position: relative;
  height: 100%;
}

#contents {
  margin: 10px;
}

.hm-400 {
  max-height: 400px;
}

.hm-400 canvas {
  max-height: 400px;
}

.overflow-y-auto {
  overflow-y: scroll;
  overflow-x: auto;
}

.required {
  margin-left: 5px;
  background-color: red;
  padding: 2px;
  color: white;
  font-size: 80%;
}

.prewrap {
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  white-space: pre-wrap;
}

#auth-signin {
  width: 100%;
  height: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding: 0;
  margin: 0;
  background-image: url(../img/bg.png);
  background-size: cover;
  background-repeat: no-repeat;
}

#auth-signin .card {
  width: 100%;
  max-width: 440px;
  padding: 15px;
  margin: auto;
}

#header {
  background-color: rgba(243, 152, 0, 1);
  color: #fff;
  background-image: url(../img/bg.png);
  background-size: cover;
  background-repeat: no-repeat;
}

#header a {
  color: #fff;
}

#header .logo {
  margin: 0;
  font-size: 16pt;
}

#footer {
  width: 100%;
  height: 60px;
  margin-top: 60px;
  line-height: 60px;
  text-align: right;
}

#footer img {
  height: 20px;
}

#scrollTop.hide {
  opacity: 0;
  visibility: hidden;
  transition: 0.2s;
}

#scrollTop.show {
  opacity: 1;
  visibility: visible;
  transition: 0.2s;
}

#scrollTop a {
  padding: 10px;
  vertical-align: middle;
  font-size: 0.4rem;
  line-height: 130%;
  border: 1px solid #f39800;
  border-radius: 5px;
  background-color: #fff;
  display: inline-block;
  text-align: center;
  position: fixed;
  bottom: 7%;
  right: 1%;
  cursor: pointer;
  z-index: 10000;
}

#scrollTop a .arrow {
  color: #f39800;
  font-size: 1.3rem;
  display: block;
  margin: 0 auto 10px;
}

table thead {
  border-bottom: 2px double #999;
  background: #efefef;
  top: 0;
  position: sticky;
}

table thead .active {
  color: #f00;
}

.bg-head {
  background-color: #e1e1e1;
  opacity: 0.9;
  border-bottom: 3px solid #ccc;
}

.btn.qa {
  max-width: 20px;
  max-height: 20px;
}

/* input[type="radio"] + .btn {
  background-color: #fff;
} */

.btn-before-result {
  background-color: #ccc;
}

.bg-pink {
  background-color: #f8d7da;
}

.z-index-10 {
  z-index: 10;
}

/* xs */
@media (max-width: 576px) {
  .xs-w-100 {
    width: 100% !important;
  }
}

/* sm */
@media (max-width: 768px) {
  .sm-w-100 {
    width: 100% !important;
  }
}

/* md */
@media (max-width: 992px) {
  .md-w-100 {
    width: 100% !important;
  }
}

/* xl */
@media (max-width: 1200px) {
  .xl-w-100 {
    width: 100% !important;
  }
}

/* xxl */
@media (max-width: 1400px) {
  .xxl-w-100 {
    width: 100% !important;
  }
}

/* ここからpdf */
#sanki-pdf {
  padding: 10px;
}
.text-vertical {
  padding-top: 20px;
  writing-mode: vertical-rl;
}
#sanki-pdf div {
  font-family: "NotoSans";
  line-height: 1.5;
}

#sanki-pdf table {
  font-family: "NotoSans";
  width: 100%;
  border: 1px solid #000;
  margin-bottom: 5px;
}
#sanki-pdf table thead {
  border: none;
  background: none;
}
#sanki-pdf table tbody tr:last-child,
#sanki-pdf table tbody tr:last-child td {
  border-bottom: none !important;
}
#sanki-pdf table .border-top {
  border-top: 1px solid #c0c0c0;
}
#sanki-pdf table .border-bottom {
  border-bottom: 1px solid #c0c0c0 !important;
}
#sanki-pdf table .border-bottom-none {
  border-bottom: none;
}
#sanki-pdf table .border-left {
  border-left: 1px solid #c0c0c0;
}
#sanki-pdf table .border-right {
  border-right: 1px solid #c0c0c0;
}
#sanki-pdf table th {
  font-size: 0.4rem;
  min-width: 20px;
}
#sanki-pdf table td.number,
#sanki-pdf table td.assessment {
  width: 20px;
}
#sanki-pdf table td.title {
  width: 624px;
}
#sanki-pdf .pdf-header {
  width: 1070px;
}
#sanki-pdf .pdf-content {
  width: 1070px;
  gap: 0 40px;
  max-height: 1600px;
}
#sanki-pdf .pdf-comment-wrap {
  width: 2200px;
  gap: 0 40px;
}
#sanki-pdf .pdf-comment {
  width: 1070px;
}
#sanki-pdf table tbody th.section-title {
  width: 20px;
  padding-inline: 5px;
  min-width: 1px;
  word-break: break-word;
}

#sanki-pdf table td {
  font-size: 0.4rem;
}

#sanki-pdf table .small-text {
  font-size: 0.4rem;
}

#sanki-pdf table li {
  list-style: none;
}

#sanki-pdf h5 {
  font-family: "NotoSans";
  font-size: 0.5rem;
  margin-bottom: 1px;
}

#sanki-pdf h6 {
  font-size: 0.4rem;
  margin-block: 1px;
}
#sanki-pdf p {
  font-size: 0.3rem;
  margin: 0;
}

#sanki-pdf .flex-container {
  display: flex;
}

#sanki-pdf .flex-item {
  flex: 1;
}
